<template>
    <div style="width: 100%">
        <div class="d-flex align-content-center position-relative">
            <div class="container-arrow-carousel arrows-carousel-left" v-if="minCarrusel">
                <a
                    href="#" 
                    @click.prevent="disminuirCarrusel"
                    class="arrow left"
                ></a>
            </div>
            <div class="flex-grow-1" style="height:100%">
                <CCollapse :show="carruselTab === 1"  class="p-arrows-carousel" >
                    <CRow class="cardPersonalizado">
                        <CCol md="12" lg="12" style="padding:0px;">
                            <div
                                class="cardHeaderPersonalizado"
                                :style="
                                'background:' + ' #4d5a70 ' + '!important;width:100%;color:#FFFFFF;font-weight:bold;'
                                "
                            >
                                <span>{{ $t('label.ShippingLines') }}</span>
                            </div>
                        </CCol>
                        <CCol md="12" lg="6" style="padding:0px;border-right: 1px solid #d8dbe0;">
                            <div style="width: 100%;" v-if="banderaLines">
                                <barVerticalNew 
                                    :series="seriesLineSize"
                                    :labels="categoriesBar"
                                    :titleYaxis="titleBars"
                                    :bandera="banderaLines"
                                    height="400px"
                                >
                                </barVerticalNew>
                            </div>
                        </CCol>
                        <CCol md="12" lg="6" style="padding:0px;">
                            <div style="width: 100%;" v-if="banderaLines">
                                <barHorizontalNew
                                    colorDatalabels="#FFF"
                                    :series="seriesLinePortActivity"
                                    :labels="categoriesBar"
                                    :titleXaxis="titleBars"
                                    :bandera="banderaLines"
                                    height="400px"
                                >
                                </barHorizontalNew>
                            </div>
                        </CCol>
                    </CRow>
                </CCollapse>
                <CCollapse v-for="item in arrayForSimpleLines" :key="item.idx" :show="carruselTab === item.carruselItem"  class="p-arrows-carousel" >
                    <div class="card" style="width: 100%;">
                        <div
                            class="card-header"
                            :style="
                                'background:' + ' #4d5a70 ' + '!important;width:100%;color:#FFFFFF;font-weight:bold;'
                            "
                        >
                            <span>{{ $t('label.MobilizedContainers') }}</span>
                        </div>
                        <div class="card-body pt-2" style="background: #EBEBEB">
                            <div class="container" style="margin-bottom:10px;">
                                <div class="row">
                                    <div class="col-lg-3 reduccionMargen" v-for="item2 in item.informationSimplePie" :key="item2.ShippingLineId">
                                        <div class="card" :style="'background:#ffffff;height:100%;border-radius: 10px;'">
                                            <div class="text-center text-white" :style="'background:'+item2.Color+';border-radius: 10px 10px 0px 0px; font-weight:bold;'">
                                                <label class="m-2">{{item2.ShippingLineCode}}</label>
                                            </div>
                                            <div class="d-flex justify-content-center">
                                                <div class="col-lg-8 mt-2">
                                                    <img style="filter: grayscale(100%);" height="120px" width="100%" :src="routeDoc(item2)" @error="setAltImg" alt="Card image cap">
                                                </div>
                                            </div>
                                            <div v-if="sinInformacion(item2.serie)">
                                                <div v-if="bandera(carruselTab,item,item2)" >
                                                    <SimpleBarShippingLines
                                                        Width="100%"
                                                        Height="100%"
                                                        :Series="SeriesShippingLines(item2.serie)"
                                                        :Categories="labelsSimplePie"
                                                        :Colors="colorStandard(item2.Color)"
                                                    >
                                                    </SimpleBarShippingLines>
                                                </div>
                                            </div>
                                            <div class="card-body d-flex align-items-center justify-content-center" v-else >
                                                <h6 style="color:#F67272;text-align:center;" >{{$t('label.noDataAvailable')}}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </CCollapse>
            </div>
            <div class="container-arrow-carousel arrows-carousel-right" v-if="maxCarrusel">
                <a
                    href="#" 
                    @click.prevent="aumentarCarrusel"
                    class="arrow right"
                ></a>
            </div>
        </div>
    </div>
</template>

<script>
    import SimpleBarShippingLines from "../../components/SimpleBarShippingLines";
    import barHorizontalNew from "../../components/barHorizontalNew";
    import barVerticalNew from "../../components/barVerticalNew";
    import { mapState } from 'vuex';
    //data
    function data() {
        return {
            interval: null,
        }
    }
    //methods
    function routeDoc(item){
        return `${this.$store.getters["connection/getBase"]}${item.LineRoute}`;
    }
    function disminuirCarrusel(){
        if(this.$store.state.situacionOperativaActual.carruselTab !== 1){
            this.$store.state.situacionOperativaActual.carruselTab = this.$store.state.situacionOperativaActual.carruselTab - 1;
        }
    }
    function aumentarCarrusel(){
        if(this.$store.state.situacionOperativaActual.carruselTab !== this.maxForCarruselLines){
            this.$store.state.situacionOperativaActual.carruselTab = this.$store.state.situacionOperativaActual.carruselTab + 1;
        }
    }
    function bandera(carruselTab,item,item2){
        return (this.$store.state.situacionOperativaActual.tabsOperationes === (this.FgGeneralCargo ? 7 : 6)) && carruselTab === item.carruselItem;
    }
    function sinInformacion(array){
        let banderita = false;
        for (let index = 0; index < array.length; index++) {
            if(array[index] !== 0){
                banderita = true;
            }
        }
        return banderita;
    }
    function SeriesShippingLines(serie){
        let data = [{
            name: 'CANT',
            data: serie,
        }];
        return data;
    }
    //computed
    function banderaLines(){
        return (this.tabsOperationes === (this.FgGeneralCargo ? 7 : 6)) && this.carruselTab === 1;
    }
    function colorStandard(Color){
        return [Color,Color+'4d'];
    }
    function maxForCarruselLines(){
        let max = 1;
        if(this.arrayForSimpleLines.length !== 0){
            let tamano = this.arrayForSimpleLines.length;
            max = this.arrayForSimpleLines[tamano-1].carruselItem;
        }
        return max;
    }
    function minCarrusel(){
        return this.$store.state.situacionOperativaActual.carruselTab > 1
    }
    function maxCarrusel(){
        return this.$store.state.situacionOperativaActual.carruselTab < this.maxForCarruselLines
    }
    function arrayForSimpleLines(){
        let data = [];
        let i = 1;
        let dataHijo = [];
        while(i <= this.informationSimplePie.length){
            if(i % 5 !== 0){
                dataHijo.push(this.informationSimplePie[i-1]);
            }else{
                let tamano = data.length;
                let inicial = 0;
                if(tamano === 0){
                    inicial = 2;
                }else{
                    inicial = data[tamano-1].carruselItem + 1;
                }
                data.push({
                    idx: i,
                    carruselItem: inicial,
                    informationSimplePie: dataHijo
                })
                dataHijo = [];
            }
            i++;
        }
        if(dataHijo.length !== 0){
            let tamano = data.length;
            let inicial = 0;
            if(tamano === 0){
                inicial = 2;
            }else{
                inicial = data[tamano-1].carruselItem + 1;
            }

            data.push({
                idx: i+1,
                carruselItem: inicial,
                informationSimplePie: dataHijo
            })
        }
        return data;
    }
    function categoriesBar(){
        let data = [];
        let DataCategories = this.LinesJson?.LinesConfigJson ? this.LinesJson.LinesConfigJson : [];
        DataCategories.map(async(item) => {
            data.push(item.ShippingLineCode);
        })
        return data;
    }
    function informationSimplePie(){
        let DataCategories = this.LinesJson?.LinesConfigJson ? this.LinesJson.LinesConfigJson : [];
        return DataCategories;
    }
    function labelsSimplePie(){
        let data = [];
        let DataLabels = this.LinesJson?.LinesExecutedJson ? this.LinesJson.LinesExecutedJson : [];
        DataLabels.map(async(item) => {
            let labels = this.$i18n.locale == 'es' ? item.Label[0].Es : item.Label[0].En
            data.push(labels);
        })
        return data;
    }
    function seriesLineSize(){
        let data = [];
        let DataLineSize = this.LinesJson?.LinesSizeJson ? this.LinesJson.LinesSizeJson : [];
        let theValueIs = this.$t('label.lang');
        DataLineSize.map(async(item) => {
            let labels = theValueIs == "ESPAÑOL" ? item.Label[0].Es : item.Label[0].En;
            data.push({
                label : labels,
                data : item.data,
                backgroundColor: item.color
            })
        })
        return data;
    }

    function seriesLinePortActivity(){
        let data = [];
        let DataLineSize = this.LinesJson?.LinesPortActivityJson ? this.LinesJson.LinesPortActivityJson : [];
        let theValueIs = this.$t('label.lang');
        DataLineSize.map(async(item) => {
            let labels = theValueIs == "ESPAÑOL" ? item.Label[0].Es : item.Label[0].En;
            data.push({
                label : labels,
                data : item.data,
                backgroundColor: item.color
            })
        })
        return data;
    }
    function titleBars(){
        return this.$t('label.QuantityContainers');
    }
    function setAltImg(event){
        event.target.src = '/img/errorimage.jpg';
    }
    export default {
        name:'Lines',
        data,
        props: {
            Vessel: {
                type: Object,
                default: function () {
                    return {};
                },
            },
            LinesJson: {
                type: Object,
                default: function () {
                    return {};
                },
            },
        },
        methods:{
            colorStandard,
            SeriesShippingLines,
            routeDoc,
            setAltImg,
            disminuirCarrusel,
            aumentarCarrusel,
            bandera,
            sinInformacion, 
            _setInterval: function() {
                this.interval = setInterval(() => {
                    if(this.$store.state.situacionOperativaActual.carruselTab !== this.maxForCarruselLines){
                        this.$store.state.situacionOperativaActual.carruselTab = this.$store.state.situacionOperativaActual.carruselTab + 1;
                    }else{
                        this.$store.state.situacionOperativaActual.carruselTab = 1;
                    }   
                }, 30000);
            }
        },
        computed:{
            categoriesBar,
            seriesLineSize,
            seriesLinePortActivity,
            titleBars,
            maxForCarruselLines,
            informationSimplePie,
            arrayForSimpleLines,
            labelsSimplePie,
            banderaLines,
            minCarrusel,
            maxCarrusel,
            ...mapState({
                FgGeneralCargo: state => state.situacionOperativaActual.FgGeneralCargo,
                FgContainerShip: state => state.situacionOperativaActual.FgContainerShip,
                carrusel: state => state.situacionOperativaActual.carrusel,
                carruselTab : state=> state.situacionOperativaActual.carruselTab,
                tabsOperationes: state=> state.situacionOperativaActual.tabsOperationes,
            })
        },
        beforeDestroy(){
            clearInterval(this.interval);
            this.interval = null;
        },
        components:{
            SimpleBarShippingLines,
            barHorizontalNew,
            barVerticalNew,
        },
        watch:{
            tabsOperationes(newValue,OldValue){
                let tabsOperation = this.FgContainerShip ? 6 : 7;
                if(OldValue === tabsOperation){
                    this.$store.state.situacionOperativaActual.carruselTab = 1;
                    clearInterval(this.interval);
                    this.interval = null;
                }else if(newValue === tabsOperation){
                    this.$nextTick(async () => {
                        if(this.carrusel){
                            this._setInterval();
                        }
                    });
                }
            },
            carrusel(newValue){
                let tabsOperation = this.FgContainerShip ? 6 : 7;
                if(this.tabsOperationes === tabsOperation){
                    if(newValue){
                        this.$nextTick(async () => {
                            this._setInterval();
                        });
                    }else{
                        clearInterval(this.interval);
                        this.interval = null;
                    }
                }
            }
        }
    }
</script>